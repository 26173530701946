import { render, staticRenderFns } from "./ProductPrice.vue?vue&type=template&id=4759dc18&scoped=true&lang=pug"
import script from "./ProductPrice.vue?vue&type=script&lang=js"
export * from "./ProductPrice.vue?vue&type=script&lang=js"
import style0 from "./ProductPrice.vue?vue&type=style&index=0&id=4759dc18&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4759dc18",
  null
  
)

export default component.exports